<template>
  <div>
    <b-row>
      <b-col>
        <ReportsFilters
          :filters="form.filters"
          :fetchData="fetchCsvData"
          :csvFields="csvFields"
          :admin="admin"
          @filtersChanged="onReportFiltersChanged"
        />
      </b-col>
    </b-row>
    <Alert
      v-if="!items.length && errorMessage"
      id="no-raffle-sales-recorded-alert"
      class="mt-2"
      icon="info"
      variant="yellow"
    >
      No raffle sales have been recorded yet. Please check back later.
    </Alert>
    <b-row class="overflow-x-hidden" v-else>
      <b-col>
        <div class="table-overflow">
          <b-table
            show-empty
            id="raffle-sales-table"
            class="orders-table"
            striped
            sort-icon-right
            :fields="table.fields"
            :per-page="table.perPage"
            :current-page="table.currentPage"
            :items="items"
            :sort-by.sync="table.sortBy"
            :sort-desc.sync="table.sortDesc"
          >
            <template #empty>
              <div class="text-left">There are no records to show.</div>
            </template>
            <template v-slot:table-busy>
              <div class="text-left">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template v-slot:cell(eventStatus)="row">
              <EventStatusBadge :status="row.item.eventStatus" />
            </template>
            <template v-slot:cell(eventDrawDate)="row">
              <DateTooltip :date="row.item.eventDrawDate" />
            </template>
            <template v-slot:cell(eventStartDate)="row">
              <DateTooltip :date="row.item.eventStartDate" />
            </template>
            <template v-slot:cell(eventEndDate)="row">
              <DateTooltip :date="row.item.eventEndDate" />
            </template>
            <template v-slot:cell(organizationName)="row">
              <router-link :to="{ path: '/admin/organization', query: { id: row.item.organizationId } }">
                <span>{{ row.item.organizationName }}</span>
              </router-link>
            </template>
            <template v-slot:cell(eventName)="row">
              <router-link :to="{ path: '/raffle', query: { id: row.item.eventId } }">
                <span>{{ row.item.eventName }}</span>
              </router-link>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        Total Records:
        <strong>{{ table.rows }}</strong>
      </b-col>
      <b-col cols="6 text-right">
        Last Refreshed:
        <strong v-if="lastRefreshed">{{ formatDateTime(lastRefreshed, { format: 'dateTime' }) }}</strong>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          v-model="table.currentPage"
          :total-rows="table.rows"
          :per-page="table.perPage"
          aria-controls="table"
          pills
          align="center"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ReportServiceV2 from '@/lib/report-service-v2';
import ReportsFilters from '@/components/reports/ReportsFilters';
import EventStatusBadge from '@/components/EventStatusBadge';
import DateTooltip from '@/components/DateTooltip';
import { subDays } from 'date-fns';
import Alert from '@/components/ui/Alert';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

export default {
  components: { EventStatusBadge, DateTooltip, ReportsFilters, Alert },
  props: ['eventId', 'organizationId', 'goldrush', 'admin'],
  data() {
    return {
      csv: null,
      errorMessage: null,
      form: {
        filters: {
          status: null,
          organization: null,
          startDate: subDays(new Date(), 30),
          endDate: null,
          province: null
        }
      },
      table: {
        isBusy: true,
        rows: 0,
        currentPage: 1,
        perPage: 10,
        sortBy: 'eventEndDate',
        sortDesc: true,
        fields: [
          { label: 'Raffle No', key: 'eventNumber', sortable: true, thClass: 'th-slim' },
          { label: 'Start Date', key: 'eventStartDate', sortable: true, thClass: 'th-regular' },
          { label: 'End Date', key: 'eventEndDate', sortable: true, thClass: 'th-regular' },
          { label: 'Draw Date', key: 'eventDrawDate', sortable: true, thClass: 'th-regular' },
          {
            label: 'Organization Name',
            key: 'organizationName',
            sortable: true,
            tdClass: !this.admin ? 'd-none' : undefined,
            thClass: !this.admin ? 'd-none' : 'th-large'
          },
          {
            label: 'Org No',
            key: 'organizationNumber',
            sortable: true,
            tdClass: !this.admin ? 'd-none' : undefined,
            thClass: !this.admin ? 'd-none' : 'th-slim'
          },
          { label: 'Raffle Name', key: 'eventName', sortable: true, thClass: 'th-large' },
          { label: 'Province', key: 'eventProvince', sortable: true, thClass: 'th-slim' },
          { label: 'Status', key: 'eventStatus', sortable: true, thClass: 'th-slim' },
          { label: 'Category', key: 'eventCategoryName', sortable: true, thClass: 'th-regular' },
          { label: 'Status', key: 'eventStatus', sortable: true, thClass: 'th-slim' },
          { label: 'Frequency', key: 'eventFrequency', sortable: true, thClass: 'th-slim' },
          {
            label: 'Orders',
            key: 'numOrders',
            thClass: 'text-right th-slim',
            tdClass: 'text-right',
            sortable: true
          },
          {
            label: 'Gross Sales',
            key: 'totalSalesCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'Refunds',
            key: 'numRefunds',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatNumberWithDash
          },
          {
            label: 'Refund Total',
            key: 'totalRefundCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'Raised',
            key: 'totalRaisedCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'Stripe Fees',
            key: 'totalMerchantFeeCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'Prize',
            key: 'totalPrizeCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'RB Order Fee',
            key: 'raffleboxOrderFeeCents',
            thClass: 'text-right th-slim',
            tdClass: 'text-right',
            sortable: false,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'RB Fee %',
            key: 'raffleboxFeePercent',
            thClass: 'text-right th-slim',
            tdClass: 'text-right',
            sortable: false,
            formatter: function (val) {
              return val ? val + '%' : '-';
            }
          },
          {
            label: 'RB Fee',
            key: 'raffleboxFeeCents',
            thClass: 'text-right th-slim',
            tdClass: 'text-right',
            sortable: false,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'PST',
            key: 'invoicePstCents',
            sortable: false,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'GST',
            key: 'invoiceGstCents',
            sortable: false,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'HST',
            key: 'invoiceHstCents',
            sortable: false,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'Net Sales',
            key: 'totalNetAmountCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          }
        ]
      },
      csvFields: {
        'Raffle Number': 'eventNumber',
        'Start Date': {
          field: 'eventStartDate',
          callback: (value) => {
            if (value) {
              return this.formatDateTime(this.parseISO(value), this.dateTimeFormatOptions);
            }
          }
        },
        'End Date': {
          field: 'eventEndDate',
          callback: (value) => {
            if (value) {
              return this.formatDateTime(this.parseISO(value), this.dateTimeFormatOptions);
            }
          }
        },
        'Draw Date': {
          field: 'eventDrawDate',
          callback: (value) => {
            if (value) {
              return this.formatDateTime(this.parseISO(value), this.dateTimeFormatOptions);
            }
          }
        },
        'Organization Name': {
          field: 'organizationName',
          callback: (value) => {
            return `"${value}"`;
          }
        },
        'Organization No': 'organizationNumber',
        'Raffle Name': {
          field: 'eventName',
          callback: (value) => {
            return `"${value}"`;
          }
        },
        Province: 'eventProvince',
        Status: 'eventStatus',
        Category: 'eventCategoryName',
        Frequency: 'eventFrequency',
        Orders: {
          field: 'numOrders',
          callback: (value) => {
            return Number(value);
          }
        },
        'Gross Sales': {
          field: 'totalSalesCents',
          callback: (value) => {
            return Number(value / 100);
          }
        },
        Refunds: {
          fields: 'numRefunds',
          callback: (value) => {
            return Number(value);
          }
        },
        'Refund Total': {
          field: 'totalRefundCents',
          callback: (value) => {
            return Number(value / 100);
          }
        },
        Raised: {
          field: 'totalRaisedCents',
          callback: (value) => {
            return Number(value / 100);
          }
        },
        'Stripe Fees': {
          field: 'totalMerchantFeeCents',
          callback: (value) => {
            return Number(value / 100);
          }
        },
        Prize: {
          field: 'totalPrizeCents',
          callback: (value) => {
            return Number(value / 100);
          }
        },
        'RB Order Fee': {
          field: 'raffleboxOrderFeeCents',
          callback: (value) => {
            return Number(value / 100);
          }
        },
        'RB Fee %': {
          field: 'raffleboxFeePercent',
          callback: (value) => {
            return Number(value);
          }
        },
        'RB Fee': {
          field: 'raffleboxFeeCents',
          callback: (value) => {
            return Number(value / 100);
          }
        },
        'Net Sales': {
          field: 'totalNetAmountCents',
          callback: (value) => {
            return Number(value / 100);
          }
        }
      },
      lastRefreshed: null,
      dateTimeFormatOptions: {}
    };
  },
  watch: {
    'table.sortBy': function () {
      this.refreshTable();
    }
  },
  methods: {
    onReportFiltersChanged(filters) {
      this.form.filters = {
        ...this.form.filters,
        ...filters
      };

      this.refreshTable();
    },
    onError(error) {
      this.$emit('error', error);
    },
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'raffle-sales-table');
    },
    async fetchCsvData() {
      // fetches the data for the CSV download
      // Only supports 1000 records thus far
      const result = await this.getEventSales(0, 1000);

      return result.data;
    },
    async getEventSales(page, pageSize) {
      const organizationId = this.$store.state.organization.id;

      const eventStatus = this.form.filters.status ? this.form.filters.status : null;
      const eventProvince = this.form.filters.province ? this.form.filters.province : undefined;

      const params = {
        organizationId,
        eventStatus,
        eventProvince,
        fromDate: this.form.filters.startDate || undefined,
        toDate: this.form.filters.endDate || undefined,
        page: page || 0,
        pageSize,
        sortBy: this.table.sortBy,
        sortDir: this.table.sortDesc === true ? 'desc' : 'asc',
        category: 'Raffles'
      };

      const result = await ReportServiceV2.eventSales(params);

      return result;
    },
    async items() {
      try {
        this.table.isBusy = true;

        const result = await this.getEventSales(this.table.currentPage - 1, 10);

        this.table.rows = result.total;

        this.lastRefreshed = this.parseISO(result.data[0].lastRefreshed);

        return result.data;
      } catch (error) {
        this.errorMessage = true;
      } finally {
        this.table.isBusy = false;
      }
    }
  },
  async mounted() {
    const sessionUser = await getAuth().sessionUser();

    this.dateTimeFormatOptions = {
      format: 'iso8601',
      timeZone: sessionUser.timeZone,
      displayTimeZone: true
    };

    this.refreshTable();
  }
};
</script>

<style>
.overflow-x-hidden {
  overflow-x: hidden;
}

.table-overflow {
  display: block;
  max-width: 100%;
  margin-bottom: 2rem;
  overflow-x: scroll;

  background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(180, 180, 180, 0.4), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(180, 180, 180, 0.4), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
}

/* Help size columns a bit more appropriately. Could still be a bit cleaner looking. */
.th-slim {
  vertical-align: middle !important;
  min-width: 6rem;
  width: 100%;
}

.th-regular {
  vertical-align: middle !important;
  min-width: 8.5rem;
  width: 100%;
}

.th-large {
  vertical-align: middle !important;
  min-width: 14rem;
  width: 100%;
}
</style>
