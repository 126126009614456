<template>
  <div class="gr-sales-by-stations">
    <b-table
      show-empty
      ref="table"
      id="table"
      striped
      sort-icon-right
      :fields="table.fields"
      :per-page="table.perPage"
      :current-page="table.currentPage"
      :items="items"
      :sort-by.sync="table.sortBy"
      :sort-desc.sync="table.sortDesc"
    >
      >
      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import ReportService from '@/lib/report-service-v2';

export default {
  components: {},
  props: ['eventId', 'drawNumber'],
  data: function () {
    return {
      selectedDate: 'Today',
      table: {
        isBusy: false,
        fields: [
          {
            key: 'organizationGroupName',
            label: 'Station',
            sortable: false
          },
          { label: 'Draw', key: 'eventDrawNum', thClass: 'text-right th-slim', tdClass: 'text-right', sortable: true },
          {
            label: 'Orders',
            key: 'numOrders',
            thClass: 'text-right th-slim',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatNumberWithDash
          },
          {
            label: 'Gross Sales',
            key: 'totalSalesCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'Future Sales',
            key: 'totalFutureSalesCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'Refunds',
            key: 'numRefunds',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: false,
            formatter: this.formatNumberWithDash
          },
          {
            label: 'Refunds Total',
            key: 'totalRefundCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'Raised',
            key: 'totalRaisedCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'RB Fee %',
            key: 'raffleboxFeePercent',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: false,
            formatter: function (val) {
              return val + '%';
            }
          },
          {
            label: 'RB Fee',
            key: 'raffleboxFeeCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: false,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'Net Sales',
            key: 'totalNetAmountCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'Show Details',
            key: 'show_details',
            thClass: 'text-right th-regular',
            sortable: false
          }
        ]
      }
    };
  },
  watch: {
    eventId: function () {
      this.refreshTable();
    },
    'table.sortBy': function () {
      this.refreshTable();
    }
  },
  computed: {
    sortDir() {
      return this.table.sortDesc === true ? 'desc' : 'asc';
    }
  },
  methods: {
    dateSelected: function (event) {
      this.selectedDate = event.srcElement.outerText;
    },
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'table');
    },
    async items() {
      try {
        this.table.isBusy = true;

        if (!this.eventId || this.eventId < 0) {
          return [];
        }

        const params = {
          eventId: this.eventId,
          drawNumber: this.drawNumber,
          sortBy: this.table.sortBy || undefined,
          sortDir: this.sortDir,
          pageSize: 100,
          category: 'Goldrush'
        };

        const result = await ReportService.eventSales(params);

        return result.data;
      } catch (error) {
        this.$emit('error', error);
      }
    }
  }
};
</script>

<style scoped>
.container-filter {
  padding-bottom: 1rem;
  padding-left: 1rem;
}

.gr-sales-by-stations {
  display: flex;
  justify-content: flex-end;
}
</style>
